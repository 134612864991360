import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Col, TabContainer } from 'react-bootstrap';
import "../common.css";
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import ScheduleService from "../../services/Schedule.services";
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import TextField from "@mui/material/TextField";
import Modal from 'react-bootstrap/Modal';
import { useSockets } from "../../context/socket.context";

interface IScheduleMaintenance {
    createdBy?: {
        id: string,
        name: string,
        role: {
            level: number,
            name: string
        },
        userName: string
    },
    serverDownTime: any,
    serverUpTime: any
}
const CreateScheduleMaintenance = () => {
    const navigate = useNavigate();
    const { socket } = useSockets();
    const [isOnSubmitting, setIsOnSubmitting] = useState<boolean>(false);
    const { handleSubmit, formState: { errors } } = useForm<IScheduleMaintenance>({});
    const [serverDownTime, setServerDownTime] = useState<Dayjs | null>(dayjs(new Date().toISOString()).add(1, 'hours'));
    const [serverUpTime, setServerUpTime] = useState<Dayjs | null>(dayjs(new Date().toISOString()).add(4, 'hours'));
    const [showDialog, setShowDialog] = useState(false);

    const handleChangeServerDownTime = (newValue: Dayjs | null) => {
        setServerDownTime(newValue);
    };

    const handleChangeServerUpTime = (newValue: Dayjs | null) => {
        setServerUpTime(newValue);
    };
    const onSubmit = async () => {
        setIsOnSubmitting(true);
        const payload: IScheduleMaintenance = {
            createdBy: {
                id: "rishabh@pokermoogley.com",
                name: "SuperAdmin",
                role: {
                    level: 7,
                    name: "admin"
                },
                userName: "Admin"
            },
            // serverDownTime: dayjs(serverDownTime).valueOf(),
            // serverUpTime: dayjs(serverUpTime).valueOf()
            serverDownTime: serverDownTime,
            serverUpTime: serverUpTime
        }
        if (payload?.serverDownTime < (new Date().getTime())) {
            setIsOnSubmitting(false);
            return toast.error("The Down Time must greater than the current time");
        }

        if (payload?.serverUpTime - payload?.serverDownTime < 600000) {
            setIsOnSubmitting(false);
            return toast.error("The Down Time must greater than the Up Time at least 3 hours");
        }
        const result: any = await CreateScheduleMaintenanceService.mutateAsync(payload);
        if (!result?.success) {
            setIsOnSubmitting(false);
            return toast.error(result?.info);
        }
        setIsOnSubmitting(false);
        // toast.success("Created successfully.");
        navigate(`/maintenance/list`);
        return result;
    }
    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);


    const CreateScheduleMaintenanceService = useMutation((data: any) => ScheduleService.scheduleMaintenance(data), {
        onSuccess: (res) => {
            console.log("CreateScheduleMaintenanceService", res);
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });

    useEffect(() => {
        socket.on("ResponseFromBE", (result) => {
            if (result?.success) {
                toast.success("Created successfully.");
                navigate(`/maintenance/list`);
            }
            else {
                toast.error(result?.info);
            }
            setIsOnSubmitting(false);
        })
        return () => {
            socket.off('ResponseFromBE');
        };
    })

    return (
        <>
            <TabContainer>
                <>
                    <Modal show={showDialog} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Warning!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to schedule maintenance?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => {
                                onSubmit();
                                handleClose();
                            }}>
                                Proceed
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
                <div className="list-tournament pb-4">
                    <Col>
                        <BreadCrumbs childItem="Create Schedule Maintenance" />
                        <div className="card m-auto custom onlyforthis">
                            <div className="portlet-title">
                                <div className='title-wrapper'>
                                    <h3 className="page-title">Create Schedule Maintenance</h3>
                                </div>
                            </div>

                            <div className="form-search mt-3">
                                <form onSubmit={handleSubmit(handleShow)} >
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className="mb-4 d-flex flex-column justify-content-center">
                                            <label className="label-bold">
                                                Server Downtime
                                            </label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} />}
                                                    ampm={false}
                                                    value={serverDownTime}
                                                    onChange={handleChangeServerDownTime}
                                                />
                                            </LocalizationProvider>
                                            {errors.serverDownTime && <div className='error'>Server Downtime is required</div>}
                                        </div>

                                        <div className="mb-4 d-flex flex-column justify-content-center">
                                            <label className="label-bold">
                                                Server Uptime
                                            </label>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} />}
                                                    ampm={false}
                                                    value={serverUpTime}
                                                    onChange={handleChangeServerUpTime}
                                                />
                                            </LocalizationProvider>
                                            {errors.serverUpTime && <div className='error'>Server Uptime is required</div>}
                                        </div>

                                    </div>

                                    <div className="button-group flex mt-4">
                                        <Button size="lg" variant="success" type="submit" disabled={isOnSubmitting}>
                                            Submit
                                        </Button>
                                        <Button size="lg" variant="primary" type="button" onClick={() => navigate(`/maintenance/list`)}>
                                            Back
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col >
                </div >
            </TabContainer >
        </>);
}

export default CreateScheduleMaintenance;