export const validationLogin = (value: any) => {
  let errors = {
    usename: "",
    password: "",
  };
  if (!value.usename) errors.usename = "Username is required!";
  if (!value.password) {
    errors.password = "Password is required!";
  } else if (value.password.length < 8) {
    errors.password = "Password must be more than eight characters!";
  }
  return errors;
};
