import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Col, TabContainer } from 'react-bootstrap';
import "../common.css";
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import VersionService from "../../services/Version.services";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

interface IGameVersion {
    appVersion: string;
    deviceType: string;
    isInMaintainance: boolean;
    isUpdateRequired: boolean;
    maintenanceState: string;
    state: string;
}
interface IItem { name: string, code: string }

const CreateGameVersion = () => {
    // constants
    const deviceTypes: Array<IItem> = [
        { code: "androidApp", name: "Android" },
        { code: "iosApp", name: "iOS" },
        { code: "mac", name: "MacOS" },
        { code: "browser", name: "Browser" },
        { code: "windows", name: "Windows" },
        { code: "website", name: "Website" }
    ];
    const statuses: Array<IItem> = [
        { code: "Active", name: "Active" },
        { code: "Inactive", name: "Inactive" },
    ];
    const navigate = useNavigate();
    // state
    const { register, handleSubmit, formState: { errors } } = useForm<IGameVersion>({});
    const [isOnSubmitting, setIsOnSubmitting] = useState<boolean>(false);

    const onSubmit = async (data: any) => {
        setIsOnSubmitting(true);
        const payload: IGameVersion = {
            appVersion: data?.appVersion,
            deviceType: data?.deviceType,
            isInMaintainance: false,
            isUpdateRequired: false,
            maintenanceState: data?.maintenanceState,
            state: data?.state
        }

        const result: any = await CreateGameVersionService.mutateAsync(payload);
        if (!result?.success) {
            setIsOnSubmitting(false);
            return result?.info ? toast.error(result?.info) : toast.error(result?.message);
        }
        setIsOnSubmitting(false);
        toast.success("Game Version created successfully.");
        navigate(`/listGameVersions`);
        return result;
    }

    const CreateGameVersionService = useMutation((data: any) => VersionService.createGameVersion(data), {
        onSuccess: (res) => {
            console.log("CreateGameVersion", res);
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });

    return (<>
        <TabContainer>
            <div className="list-tournament pb-4">
                <Col>
                    <BreadCrumbs childItem="Create Game Version" />
                    <div className="card m-auto custom onlyforthis">
                        <div className="portlet-title">
                            <div className='title-wrapper'>
                                <h3 className="page-title">Create Game Version</h3>
                            </div>
                        </div>

                        <div className="form-search mt-3">
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-12 mb-4">
                                        <label className="label-bold">
                                            Version
                                        </label>
                                        <input
                                            id="name"
                                            type="text"
                                            placeholder="Version"
                                            className="form-control"
                                            {...register("appVersion", {
                                                required: true,
                                                pattern: {
                                                    value: /^\d+(\.\d+)?$/,
                                                    message: "Invalid version format, must be like 1.0, 1.1, etc."
                                                }
                                            })}
                                        />
                                        {errors.appVersion && <div className='error'>Version is required</div>}
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-12 mb-4">
                                        <label className="label-bold">
                                            Device type
                                        </label>
                                        <Form.Group controlId="SelectCustom">
                                            <Form.Control as="select" {...register("deviceType", { required: true })} >
                                                {deviceTypes?.map((value, index) => {
                                                    return (
                                                        <option key={`dtype_${index}`} value={value.code}>{value.name}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        {errors.deviceType && <div className='error'>Device type is required</div>}
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-12 mb-4">
                                        <label className="label-bold">
                                            Status
                                        </label>
                                        <Form.Group controlId="SelectCustom">
                                            <Form.Control as="select" {...register("state", { required: true })}>
                                                {statuses?.map((value, index) => {
                                                    return (
                                                        <option key={`state_${index}`} value={value.code}>{value.name}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        {errors.state && <div className='error'>Status is required</div>}
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-12 mb-4">
                                        <label className="label-bold">
                                            Maintenance Status
                                        </label>
                                        <Form.Group controlId="SelectCustom">
                                            <Form.Control as="select" {...register("maintenanceState", { required: true })}>
                                                {statuses?.map((value, index) => {
                                                    return (
                                                        <option key={`mstate_${index}`} value={value.code}>{value.name}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        {errors.maintenanceState && <div className='error'>Maintenance Status is required</div>}
                                    </div>
                                </div>

                                <div className="button-group flex mt-4">
                                    <Button size="lg" variant="success" type="submit" disabled={isOnSubmitting}>
                                        Submit
                                    </Button>
                                    <Button size="lg" variant="primary" type="button" onClick={() => navigate(`/listGameVersions`)}>
                                        Back
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Col>
            </div>
        </TabContainer>
    </>);
}
export default CreateGameVersion;