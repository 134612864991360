import { io, Socket } from "socket.io-client";
import config from "../config/config";
import { createContext, useContext, useEffect, useState } from "react";

const socket = io(config.socketUrl, {
  auth: { access_token: (typeof window !== "undefined") ? localStorage.getItem("access_token") : '', },
  transports: ["websocket"]
});
interface Context {
  socket: Socket;
  username?: string;
  setUsername: Function;
  setMessages: Function;
  errorInfo: any;
  setErrorInfo?: any;

}

const SocketContext = createContext<Context>({
  socket,
  setUsername: () => false,
  setMessages: () => false,
  errorInfo: {},
});

function SocketsProvider(props: any) {
  const [username, setUsername] = useState("");
  const [errorInfo, setErrorInfo] = useState(null);
  window.onfocus = function () {

  };
  useEffect(() => {
    socket.on("connect", () => {
      console.log("socket is connected with id", socket.id); // x8WIv7-mJelg7on_ALbx
    });

    // listen from all events
    socket.onAny((event, ...args) => {
      console.log("++++++++++++++++++++++++++++++ START ++++++++++++++++++++++++++++++");
      console.log(`Time: ${new Date().toLocaleString()}. Event: ${event}`);
      if (args[0]?.event) {
        console.log(`name: ${args[0]?.event}`);
      }
      if (args[0]?.eventType) {
        console.log(`eventType: ${args[0]?.eventType}`);
      }
      if (args[0]?.eventTo) {
        console.log(`Listened from: ${args[0]?.eventTo}`);
      }
      console.log("Event Data: ", args[0]);
      console.log("=============================== END ==================================");
    });
    socket.on("disconnect", () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
      console.log("socket disconnected: " + new Date());
    });

    /////////////////////
    socket.on("connect_error", (err: any) => {
      //console.log(err);
      setErrorInfo(err);
      if (err.message === "invalid username" || err.message === "Invalid Session") {
        console.log(err.message);
      }
    });

    socket.on("game-maintenance", (value) => {
      console.log(value);
    });


    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('disconnect');
      socket.offAny();
    };

  }, [socket]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        username,
        setUsername,
        errorInfo,
        setErrorInfo,
      }}
      {...props}
    />
  );
}

export const useSockets = () => useContext(SocketContext);

export default SocketsProvider;
