import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AuthLayout from "./layout/AuthLayout/AuthLayout";
import DashboardLayout from "./layout/DashboardLayout/DashboardLayout";
import Login from "./pages/login/Login";
import CreateGameVersion from "./pages/VersionManagement/CreateGameVersion";
import EditGameVersion from "./pages/VersionManagement/EditGameVersion";
import ListGameVersion from "./pages/VersionManagement/ListGameVersion";
import CreateScheduleMaintenance from "./pages/ScheduleMaintenance/CreateScheduleMaintenance";
import EditScheduleMaintenance from "./pages/ScheduleMaintenance/EditScheduleMaintenance";
import ListScheduleMaintenance from "./pages/ScheduleMaintenance/ListScheduleMaintenance";

function App() {
  const token = localStorage.getItem("access_token");
  return (
    <BrowserRouter>
      <Routes>
        {/* LAYOUT DASHBOARD */}
        {/* <Route path="*" element={token ? <DashboardLayout /> : <Login />} /> */}
        {token ? (
          <Route path="/" element={<DashboardLayout />}>
            <Route path="*" element={<Navigate to="/" />} />

            {/* VERSION MANAGEMENT REPORT */}

            <Route path="/createGameVersion" element={<CreateGameVersion />} />
            <Route path="/gameVersion/edit/:versionId" element={<EditGameVersion />} />
            <Route path="/listGameVersions" element={<ListGameVersion />} />

            {/* SCHEDULE MAINTENANCE */}
            <Route path="/maintenance/create" element={<CreateScheduleMaintenance />} />
            <Route path="/maintenance/edit/:maintId" element={<EditScheduleMaintenance />} />
            <Route path="/maintenance/list" element={<ListScheduleMaintenance />} />

          </Route>
        ) : (
          <Route path="/" element={<AuthLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
            <Route index element={<Login />} />
          </Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
