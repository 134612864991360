import classNames from "classnames/bind";
import styles from "./sidebar.module.css";
import { ImHome } from "react-icons/im";

import SidebarData from "./sidebar-data/SidebarData";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../utils/context/ThemeProvider";

const cx = classNames.bind(styles);
function Sidebar() {
  const listName = [
    {
      id: 0,
      name: "Version & Maintenance",
      isActive: false,
      children: [
        {
          name: "Create New Version",
          destination: "createGameVersion",
        },
        {
          name: "List Versions",
          destination: "listGameVersions",
        },
        {
          name: "Schedule Maintenance",
          destination: "maintenance/list",
        },
      ],
    }
  ];
  const context = useContext(ThemeContext);
  return (
    <div className={cx("container")}>
      <ul
        className={cx(
          "sidebar-container",
          `${context?.isActiveMenu ? "active" : ""}`
        )}
      >
        <Link className={cx("link")} to="/dashboard">
          <li
            className={cx("dashboard", "wrapper")}
            onClick={() => context?.handleIsActiveMenu()}
          >
            <div className={cx("sidebar-icon")}>
              <ImHome />
              <span>Dashboard</span>
            </div>
          </li>
        </Link>

        {listName.map((menu, index) => (
          <div key={index}>
            <SidebarData
              id={menu.id}
              name={menu.name}
              children={menu.children}
              index={index}
            />
          </div>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
