import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Col, TabContainer } from 'react-bootstrap';
import "../common.css";
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import ScheduleService from "../../services/Schedule.services";
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import TextField from "@mui/material/TextField";
import Loading from '../../components/loading/Loading';
import { useSockets } from "../../context/socket.context";
interface IScheduleMaintenance {
    _id: string,
    createdBy?: {
        id: string,
        name: string,
        role: {
            level: number,
            name: string
        },
        userName: string
    },
    serverDownTime: any,
    serverUpTime: any
}
interface IScheduleTask {
    _id: string,
    type: string,
    createdAt: number,
    createdBy: {
        name: string,
        userName: string,
        role: {
            name: string,
            level: number
        },
        id: string
    },
    serverDownTime: any,
    serverUpTime: any,
    disableGameStartTime: number,
    disableLoginTime: number,
    status: string,
    logs: any[]
}
const EditScheduleMaintenance = () => {
    const navigate = useNavigate();
    const { maintId } = useParams();
    const { socket } = useSockets();

    const [isOnSubmitting, setIsOnSubmitting] = useState<boolean>(false);
    const { handleSubmit, formState: { errors } } = useForm<IScheduleMaintenance>({});
    const [serverDownTime, setServerDownTime] = useState<Dayjs | null>(dayjs(new Date().toISOString()));
    const [serverUpTime, setServerUpTime] = useState<Dayjs | null>(dayjs(new Date().toISOString()));

    const handleChangeServerDownTime = (newValue: Dayjs | null) => {
        setServerDownTime(newValue);
    };

    const handleChangeServerUpTime = (newValue: Dayjs | null) => {
        setServerUpTime(newValue);
    };
    const onSubmit = async () => {
        setIsOnSubmitting(true);
        const payload: IScheduleMaintenance = {
            _id: String(maintId),
            createdBy: {
                id: "rishabh@pokermoogley.com",
                name: "SuperAdmin",
                role: {
                    level: 7,
                    name: "admin"
                },
                userName: "Admin"
            },
            // serverDownTime: dayjs(serverDownTime).valueOf(),
            // serverUpTime: dayjs(serverUpTime).valueOf()
            serverDownTime: serverDownTime,
            serverUpTime: serverUpTime
        }
        if (payload?.serverDownTime < (new Date().getTime())) {
            setIsOnSubmitting(false);
            return toast.error("The Down Time must greater than the current time");
        }
        console.log(payload?.serverUpTime - payload?.serverDownTime);
        if (payload?.serverUpTime - payload?.serverDownTime < 600000) {
            setIsOnSubmitting(false);
            return toast.error("The Down Time must greater than the Up Time at least 3 hours");
        }

        const result: any = await EditScheduleMaintenanceService.mutateAsync(payload);
        if (!result?.success) {
            setIsOnSubmitting(false);
            return toast.error(result?.info);
        }

        // toast.success("Update successfully.");
        // navigate(`/maintenance/list`);
        setIsOnSubmitting(false);
        return result;
    }

    const EditScheduleMaintenanceService = useMutation((data: any) => ScheduleService.editMaintenance(data), {
        onSuccess: (res) => {
            console.log("EditScheduleMaintenanceService", res);
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });

    const { isLoading } = useQuery(["GET_SCHEDULE_MAINTENANCE"], () =>
        ScheduleService.listMaintenances({ limit: 1, skip: 0, _id: maintId }),
        {
            onSuccess: (res: any) => {
                if (res.success) {
                    const tasks = Array.isArray(res?.result) ? Array.from<IScheduleTask>(res?.result) : [];
                    if (tasks?.length > 0) {
                        setServerDownTime(dayjs(tasks[0]?.serverDownTime));
                        setServerUpTime(dayjs(tasks[0]?.serverUpTime));
                        setIsOnSubmitting(false);
                    }
                    else {
                        toast.error("Failed to load data.");
                        setIsOnSubmitting(true);
                    }
                }
                else {
                    toast.error(res?.data?.message);
                }
            },
            onError: () => {
                toast.error("Connect to server failed.");
            },
        });
    useEffect(() => {
        socket.on("ResponseFromBE", (result) => {
            if (result?.success) {
                toast.success("Update successfully.");
                navigate(`/maintenance/list`);
            }
            else {
                toast.error(result?.info);
            }
            setIsOnSubmitting(false);
        })
        return () => {
            socket.off('ResponseFromBE');
        };
    })
    return (
        <>
            {isLoading ?
                <Loading />
                :
                <TabContainer>
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs childItem="Edit Schedule Maintenance" />
                            <div className="card m-auto custom onlyforthis">
                                <div className="portlet-title">
                                    <div className='title-wrapper'>
                                        <h3 className="page-title">Edit Schedule Maintenance</h3>
                                    </div>
                                </div>

                                <div className="form-search mt-3">
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <div className="d-flex flex-column justify-content-center">
                                            <div className="mb-4 d-flex flex-column justify-content-center">
                                                <label className="label-bold">
                                                    Server Downtime
                                                </label>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateTimePicker
                                                        renderInput={(props) => <TextField {...props} />}
                                                        ampm={false}
                                                        value={serverDownTime}
                                                        onChange={handleChangeServerDownTime}
                                                    />
                                                </LocalizationProvider>
                                                {errors.serverDownTime && <div className='error'>Server Downtime is required</div>}
                                            </div>

                                            <div className="mb-4 d-flex flex-column justify-content-center">
                                                <label className="label-bold">
                                                    Server Uptime
                                                </label>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DateTimePicker
                                                        renderInput={(props) => <TextField {...props} />}
                                                        ampm={false}
                                                        value={serverUpTime}
                                                        onChange={handleChangeServerUpTime}
                                                    />
                                                </LocalizationProvider>
                                                {errors.serverUpTime && <div className='error'>Server Uptime is required</div>}
                                            </div>

                                        </div>

                                        <div className="button-group flex mt-4">
                                            <Button size="lg" variant="success" type="submit" disabled={isOnSubmitting}>
                                                Submit
                                            </Button>
                                            <Button size="lg" variant="primary" type="button" onClick={() => navigate(`/maintenance/list`)}>
                                                Back
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col >
                    </div >
                </TabContainer >
            }
        </>);
}
export default EditScheduleMaintenance;