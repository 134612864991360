import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import "../../styles/loading.css";

interface props {
  type?: any;
  color?: any;
}

const Loading = ({ type, color }: props) => {
  return (
    <ReactLoading
      className="loading"
      type={type || "bubbles"}
      color={color || "#fb5557"}
      height={100}
      width={100}
    />
  );
};

export default Loading;
