import { useState } from 'react';
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Config from '../../config/config';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Col, TabContainer } from 'react-bootstrap';
import "../common.css";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaEdit } from "react-icons/fa";
import Loading from '../../components/loading/Loading';
import VersionService from "../../services/Version.services";

interface IGameVersion {
    _id: string,
    appVersion: string,
    deviceType: string,
    state: string,
    maintenanceState: string,
    isUpdateRequired: boolean,
    isInMaintainance: boolean,
    createdAt: number
}
const LIMIT_PAGE = Config.limitPage;
interface IItem { name: string, code: string };
const ListGameVersion = () => {
    const deviceTypes: Array<IItem> = [
        { code: "androidApp", name: "Android" },
        { code: "iosApp", name: "iOS" },
        { code: "mac", name: "MacOS" },
        { code: "browser", name: "Browser" },
        { code: "windows", name: "Windows" },
        { code: "website", name: "Website" }
    ];

    const [tableData, setTableData] = useState<IGameVersion[]>([])
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(1);
    const totalPage = Math.ceil(total / LIMIT_PAGE);
    const handlePageChange = (e: any) => {
        const { selected } = e;
        setPage(selected);
    };
    const { isLoading } = useQuery(["GET_GAME_VERSION", page], () =>
        VersionService.listGameVersions({ limit: LIMIT_PAGE, skip: page * LIMIT_PAGE }),
        {
            onSuccess: (res: any) => {
                if (res.success) {
                    const verions = Array.isArray(res?.result) ? Array.from<IGameVersion>(res?.result) : [];
                    setTableData(verions);
                    setTotal(res?.count);
                }
                else {
                    toast.error(res?.data?.message);
                }
            },
            onError: () => {
                toast.error("Connect to server failed.");
            },
        });

    const displayData = tableData?.map(({ _id, appVersion, deviceType, state, maintenanceState, createdAt }, index: number) => {
        return (
            <tr key={index}>
                <td>{LIMIT_PAGE * page + (index + 1)}</td>
                <td>{appVersion}</td>
                <td>{deviceTypes?.find(ele => ele.code === deviceType)?.name}</td>
                <td>{state}</td>
                <td>{maintenanceState}</td>
                <td>{moment(createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                <td>
                    <Link to={`/gameVersion/edit/${_id}`}>
                        <Button className=" action-icon" variant="outline-info">
                            <div className='d-flex flex-row align-items-center'><FaEdit /><div className='ms-2'>Edit</div></div>
                        </Button>
                    </Link>
                </td>
            </tr>
        );
    });

    return (<>
        {
            isLoading ?
                <Loading /> :
                <TabContainer>
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem="List Game Version" />

                            <Col>
                                <div className="card m-auto custom onlyforthis">
                                    <div className="portlet-title">
                                        <div className='title-wrapper'>
                                            <h3 className="page-title">List Game Versions</h3>
                                        </div>
                                    </div>
                                    <div className="table-data ">
                                        <table className="table table-bordered mt-5">
                                            <thead>
                                                <tr className="trTournament">
                                                    <th>No.</th>
                                                    <th>Version</th>
                                                    <th>Device Type </th>
                                                    <th>Status</th>
                                                    <th>Maintenance Status</th>
                                                    <th>Created At</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>{displayData}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </Col>
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                forcePage={page}
                                pageCount={totalPage}
                                onPageChange={handlePageChange}
                                activeClassName={"page-item active"}
                                disabledClassName={"page-item disabled"}
                                containerClassName={"pagination"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                pageLinkClassName={"page-link"}
                            />
                        </Col>
                    </div>
                </TabContainer>
        }

    </>);
}

export default ListGameVersion;