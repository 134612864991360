import React, { createContext, useState } from "react";
interface Menu {
  isActiveMenu: boolean;
  handleIsActiveMenu: any;
}
const ThemeContext = createContext<Menu | null>(null);
const ThemeProvider = ({ children }: any) => {
  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const handleIsActiveMenu = () => {
    setIsActiveMenu(!isActiveMenu);
  };
  const value = {
    isActiveMenu,
    handleIsActiveMenu,
  };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
