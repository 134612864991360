import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Col, TabContainer } from 'react-bootstrap';
import "../common.css";
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import ScheduleService from "../../services/Schedule.services";
import { FaCross, FaEdit, FaPlus } from "react-icons/fa";
import Config from '../../config/config';
import Loading from '../../components/loading/Loading';
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useSockets } from "../../context/socket.context";

interface IScheduleTask {
    _id: string,
    type: string,
    createdAt: number,
    createdBy: {
        name: string,
        userName: string,
        role: {
            name: string,
            level: number
        },
        id: string
    },
    serverDownTime: number,
    serverUpTime: number,
    disableGameStartTime: number,
    disableLoginTime: number,
    status: string,
    logs: any[]
}
const LIMIT_PAGE = Config.limitPage;
const ListScheduleMaintenance = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { socket } = useSockets();

    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(1);
    const totalPage = Math.ceil(total / LIMIT_PAGE);
    const [tableData, setTableData] = useState<IScheduleTask[]>([]);
    const [subTextInfo, setSubTextInfo] = useState<string>("");
    const [isOnCancelling, setIsOnCancelling] = useState<boolean>(false);
    const handlePageChange = (e: any) => {
        const { selected } = e;
        setPage(selected);
    };

    useQuery(["GET_SERVER_STATE", page], () =>
        ScheduleService.getServerState({}),
        {
            onSuccess: (res: any) => {
                if (res.success) {
                    setSubTextInfo(res?.info);
                    console.log(res)
                }
                else {
                    toast.error(res?.data?.message);
                }
            },
            onError: () => {
                toast.error("Connect to server failed.");
            },
        });

    const { isLoading, data: maintenanceData } = useQuery(["GET_SCHEDULE_MAINTENANCE", page], () =>
        ScheduleService.listMaintenances({ limit: LIMIT_PAGE, skip: page * LIMIT_PAGE }),
        {
            onSuccess: (res: any) => {
                if (res.success) {
                    const tasks = Array.isArray(res?.result) ? Array.from<IScheduleTask>(res?.result) : [];
                    setTableData(tasks);
                    setTotal(res?.count);
                }
                else {
                    toast.error(res?.data?.message);
                }
            },
            onError: () => {
                toast.error("Connect to server failed.");
            },
        });


    const CancelScheduleMaintenanceService = useMutation((data: any) => ScheduleService.cancelMaintenance(data), {
        onSuccess: (res: any) => {
            console.log("CancelScheduleMaintenanceService", res);
            if (res.success) {
                queryClient.invalidateQueries(['GET_SCHEDULE_MAINTENANCE']);
            } else {
                toast.error(res.info);
            }
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });

    const onCancelScheduleTask = async (_id: string) => {
        console.log("_id: ", _id);
        setIsOnCancelling(true);
        await CancelScheduleMaintenanceService.mutateAsync({ _id });
        setIsOnCancelling(false);
    };

    const changeServerState = async () => {
        var data: any = {};
        data.createdBy = { name: "SuperAdmin", userName: "admin" };
        const result: any = await changeStateImmediately.mutateAsync(data);
        if (!result?.success) {
            return toast.error(result?.info);
        }
        queryClient.invalidateQueries(['GET_SCHEDULE_MAINTENANCE']);
        document.location.reload();
    };

    const changeStateImmediately = useMutation((data: any) => ScheduleService.changeStateImmediately(data), {
        onSuccess: (res) => {
            console.log("changeStateImmediatelyService", res);
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });

    const displayData = tableData?.map(({ _id, serverDownTime, serverUpTime, createdAt, status }, index: number) => {
        return (
            <tr key={index}>
                <td>{LIMIT_PAGE * page + (index + 1)}</td>
                <td>{moment(serverDownTime).format("DD/MM/YYYY HH:mm")}</td>
                <td>{moment(serverUpTime).format("DD/MM/YYYY HH:mm")}</td>
                <td>{status}</td>
                <td>
                    <div className="d-flex flex-row justify-content-center">

                        <Button className=" action-icon" variant="outline-info" onClick={() => navigate(`/maintenance/edit/${_id}`)}>
                            <div className='d-flex flex-row align-items-center'><FaEdit /><div className='ms-2'>Edit</div></div>
                        </Button>

                        <Button className="action-icon ms-2" variant="outline-danger" onClick={() => onCancelScheduleTask(_id)} disabled={status == "CANCELLED" || isOnCancelling}>
                            <div className='d-flex flex-row align-items-center'><FaCross /><div className='ms-2'>Cancel</div></div>
                        </Button>
                    </div>

                </td>
            </tr>
        );
    });

    useEffect(() => {
        socket.on("ResponseFromBE", (result) => {
            if (result?.success) {
                if (result?.type === "cancelMaintenance") {
                    toast.success("Cancelled successfully.");
                }
                if (result?.type === "getServerState") {
                    toast.success(result?.info || "");
                }
            }
            else {
                toast.error(result?.info);
            }
        })
        return () => {
            socket.off('ResponseFromBE');
        };
    })

    return (<>
        {
            isLoading ?
                <Loading /> :
                <TabContainer>
                    <div className="list-tournament pb-4">
                        <Col>
                            <BreadCrumbs
                                childItem="List Scheduled Maintenance" />

                            <Col>
                                <div className="card m-auto custom onlyforthis mt-5">
                                    <div className="portlet-title">
                                        <div className='title-wrapper'>
                                            <h3 className="page-title">	List Scheduled Maintenance </h3>
                                        </div>
                                    </div>

                                    <div className='flex justify-content-center mt-5'>
                                        <h3>{subTextInfo}</h3>
                                        {subTextInfo.length > 0 && <div>
                                            {subTextInfo != 'Everything is up and running.' ? <a className="btn green" style={{ border: '1px solid', color: "#fff", background: "#198754", fontSize: "15px" }} onClick={changeServerState} > Switch to Running</a> : ""}
                                        </div>}
                                    </div>

                                    <div className="table-data ">
                                        <table className="table table-bordered mt-5">
                                            <thead>
                                                <tr className="trTournament">
                                                    <th>No.</th>
                                                    <th>Server Downtime</th>
                                                    <th>Server Uptime</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>{displayData}</tbody>
                                        </table>
                                    </div>

                                    <div className='d-flex p-3 justify-content-end'>
                                        <Button size="lg" variant="success" type="button" onClick={() => navigate(`/maintenance/create`)}>
                                            <div className='d-flex flex-row align-items-center'><FaPlus /><div className='ms-2'>Add</div></div>
                                        </Button>
                                    </div>
                                </div>

                            </Col>
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                forcePage={page}
                                pageCount={totalPage}
                                onPageChange={handlePageChange}
                                activeClassName={"page-item active"}
                                disabledClassName={"page-item disabled"}
                                containerClassName={"pagination"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                pageLinkClassName={"page-link"}
                            />
                        </Col>
                    </div>
                </TabContainer >
        }

    </>);
}

export default ListScheduleMaintenance;