import { useEffect, useMemo, useState } from 'react';
import BreadCrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Col, TabContainer } from 'react-bootstrap';
import "../common.css";
import { Button } from 'react-bootstrap';
import Loading from '../../components/loading/Loading';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import VersionService from "../../services/Version.services";
import { useParams, useNavigate } from 'react-router-dom';

interface IGameVersion {
    _id: string,
    appVersion: string;
    deviceType: string;
    isInMaintainance: boolean;
    isUpdateRequired: boolean;
    maintenanceState: string;
    state: string;
}
interface IRoyalGameFieldFormErrors {
    appVersionErrText?: string;
    deviceTypeErrText?: string;
    isInMaintainanceErrText?: string;
    isUpdateRequiredErrText?: string;
    maintenanceStateErrText?: string;
    stateErrText?: string;
}
// interface IBeValidationError { message: string, field: string }
interface IItem { name: string, code: string }


const EditGameVersion = () => {
    // constants
    const deviceTypes: Array<IItem> = [
        { code: "androidApp", name: "Android" },
        { code: "iosApp", name: "iOS" },
        { code: "mac", name: "MacOS" },
        { code: "browser", name: "Browser" },
        { code: "windows", name: "Windows" },
        { code: "website", name: "Website" }
    ];
    const statuses: Array<IItem> = [
        { code: "Active", name: "Active" },
        { code: "Inactive", name: "Inactive" },
    ]
    const { versionId } = useParams();
    const [gameVersionData, setGameVersionData] = useState<IGameVersion>();
    const [isOnSubmitting, setIsOnSubmitting] = useState<boolean>(true);

    const { register, handleSubmit, reset, formState: { errors } } = useForm<IGameVersion>({
        defaultValues: useMemo(() => {
            return gameVersionData;
        }, [gameVersionData])
    });
    const navigate = useNavigate();
    // state
    const { isLoading, refetch } = useQuery(["GET_GAME_VERSION"], () =>
        VersionService.listGameVersions({ limit: 1, skip: 0, _id: versionId }), {
        onSuccess: (res: any) => {
            if (res.success) {
                const verions = Array.isArray(res?.result) ? Array.from<IGameVersion>(res?.result) : [];
                if (verions?.length > 0) {
                    setGameVersionData(verions[0]);
                    setIsOnSubmitting(false);
                }
                else {
                    toast.error("Failed to load data.");
                    setIsOnSubmitting(true);
                }
            }
            else {
                toast.error(res?.data?.message);
                setIsOnSubmitting(true);
            }
        },
        onError: () => {
            toast.error("Connect to server failed.");
            setIsOnSubmitting(true);
        },
    });

    const onSubmit = async (data: any) => {
        setIsOnSubmitting(true);
        const payload: IGameVersion = {
            _id: String(versionId),
            appVersion: data?.appVersion,
            deviceType: data?.deviceType,
            isInMaintainance: false,
            isUpdateRequired: data?.state == "Active" ? false : true,
            maintenanceState: data?.maintenanceState,
            state: data?.state
        }

        const result: any = await EditGameVersion.mutateAsync(payload);
        if (!result?.success) {
            setIsOnSubmitting(false);
            return toast.error(result?.info);
        }
        setIsOnSubmitting(false);
        toast.success("Game Version updated successfully.");
        navigate(`/listGameVersions`);
        return result;
    }
    const EditGameVersion = useMutation((data: any) => VersionService.editGameVersion(data), {
        onSuccess: (res) => {
            console.log("EditGameVersion", res);
        },
        onError: (error: any) => {
            toast.error(error?.response?.data[0]?.message)
        },
    });
    useEffect(() => {
        reset(gameVersionData);
    }, [gameVersionData]);
    return (<>
        {isLoading ?
            <Loading /> :
            <TabContainer>
                <div className="list-tournament pb-4">
                    <Col>
                        <BreadCrumbs
                            childItem="Edit Game Version" />

                        <div className="card m-auto custom onlyforthis">
                            <div className="portlet-title">
                                <div className='title-wrapper'>
                                    <h3 className="page-title">Edit Game Version</h3>
                                </div>
                            </div>

                            <div className="form-search mt-3">
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-12 mb-4">
                                            <label className="label-bold">
                                                Version
                                            </label>
                                            <input
                                                id="name"
                                                type="text"
                                                placeholder="Version"
                                                className="form-control"
                                                disabled={true}
                                                defaultValue={gameVersionData?.appVersion}
                                                {...register("appVersion", { required: true })}
                                            />
                                            {errors.appVersion && <div className='error'>Version is required</div>}
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-12 mb-4">
                                            <label className="label-bold">
                                                Device type
                                            </label>
                                            <Form.Group controlId="SelectCustom">
                                                <Form.Control as="select" {...register("deviceType", { required: true })} disabled={true} defaultValue={gameVersionData?.deviceType}>
                                                    {deviceTypes?.map((value, index) => {
                                                        return (
                                                            <option key={`dtype_${index}`} value={value.code}>{value.name}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                            {errors.deviceType && <div className='error'>Device type is required</div>}
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-12 mb-4">
                                            <label className="label-bold">
                                                Status
                                            </label>
                                            <Form.Group controlId="SelectCustom">
                                                <Form.Control as="select" {...register("state", { required: true })} defaultValue={gameVersionData?.state}>
                                                    {statuses?.map((value, index) => {
                                                        return (
                                                            <option key={`state_${index}`} value={value.code}>{value.name}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                            {errors.state && <div className='error'>Status is required</div>}
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-12 mb-4">
                                            <label className="label-bold">
                                                Maintenance Status
                                            </label>
                                            <Form.Group controlId="SelectCustom">
                                                <Form.Control as="select" {...register("maintenanceState", { required: true })} defaultValue={gameVersionData?.maintenanceState}>
                                                    {statuses?.map((value, index) => {
                                                        return (
                                                            <option key={`mstate_${index}`} value={value.code}>{value.name}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                            {errors.maintenanceState && <div className='error'>Maintenance Status is required</div>}
                                        </div>
                                    </div>

                                    <div className="button-group flex mt-4">
                                        <Button size="lg" variant="success" type="submit" disabled={isOnSubmitting}>
                                            Submit
                                        </Button>
                                        <Button size="lg" variant="primary" type="button" onClick={() => navigate(`/listGameVersions`)}>
                                            Back
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col>
                </div>
            </TabContainer>}
    </>);
}

export default EditGameVersion;