import axios, { AxiosRequestConfig } from "axios";
import Config from "../../config/config";
const baseURL = Config.apiUrl;

const AxiosInstance = axios.create({
  baseURL: baseURL,
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  function (request: AxiosRequestConfig<any> | any) {
    // Do something before request is sent
    request.headers["Authorization"] = `Bearer ${getLocalAccessToken()}`;
    return request;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  async function (response: any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // const config: AxiosRequestConfig<any> | any = response.config;
    return response && response.data ? response.data : response;
  },
  async function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const config: AxiosRequestConfig<any> | any = error.config;

    // HANDLE TOKEN EXPIRED
    if (error && error.response.status === 401) {
      //TOKEN EXPIRED
      // const accessToken: any = await refreshToken(); // GET API CALL REFRESHTOKEN
      localStorage.removeItem("access_token");
      window.location.href = window.location.origin;
      // if (accessToken.success) {
      //   //SET HEADERS ACCESS TOKEN NEW
      //   config.headers["Authorization"] = `Bearer ${accessToken.token}`;

      //   // SET CLIENT ACCESS TOKEN NEW

      //   localStorage.setItem("access_token", accessToken.token);

      //   return AxiosInstance(config);
      // }
    }

    return error && error.response && error.response.data
      ? error.response.data
      : Promise.reject(error);
  }
);

const refreshToken = () => {
  /*return axios.post(
    "https://dev.digipaysol.com/trivia-dashboard/dashboard/api/refreshToken",
    null
  );*/
  return axios.post("http://localhost:3002/dashboard/api/refreshToken", null);
};

const getLocalAccessToken = () => {
  return localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : null;
};

export default AxiosInstance;
