import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ThemeProvider } from "../../utils/context/ThemeProvider";
import "./DashboardLayout.css";
import SocketsProvider from "../../context/socket.context";
function DashboardLayout() {
  const [isActiveMenu, setIsActiveMenu] = useState(false);

  const handleActiveMenuMobile = () => {
    setIsActiveMenu(!isActiveMenu);
  };


  return (
    <ThemeProvider>
      <SocketsProvider>
        <div className="dashboard">
          <div className="layout"></div>
          <div className="header">
            <Navbar />
          </div>
          <div className="main d-flex">
            <div className="sidebar">
              <Sidebar />
            </div>
            <div className="content-main">
              <div className="sidebar-mobile">
                <Sidebar />
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </SocketsProvider>
    </ThemeProvider>
  );
}

export default DashboardLayout;
