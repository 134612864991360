import Config from "../config/config";
import AxiosInstance from "../utils/axios/AxiosInstance";

const scheduleMaintenance = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "scheduleMaintenance", data);
};

const countMaintenances = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "countMaintenances", data);
};

const listMaintenances = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "listMaintenances", data);
};

const editMaintenance = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "editMaintenance", data);
};

const cancelMaintenance = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "cancelMaintenance", data);
};

const maintainanceAndUpdate = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "maintainanceAndUpdate", data);
};

const getServerState = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "getServerState", data);
};

const changeStateImmediately = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "changeStateImmediately", data);
};


const GameScheduleService = {
  scheduleMaintenance,
  countMaintenances,
  listMaintenances,
  editMaintenance,
  cancelMaintenance,
  maintainanceAndUpdate,
  getServerState,
  changeStateImmediately
};

export default GameScheduleService;
