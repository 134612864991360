import classNames from "classnames/bind";
import { useContext } from "react";
import { BiLogOutCircle } from "react-icons/bi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { RiMenu3Fill } from "react-icons/ri";
import { ThemeContext } from "../../utils/context/ThemeProvider";
import styles from "./navbar.module.css";
const cx = classNames.bind(styles);

const Navbar = () => {
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location.href = "/";
  };

  const hanldeActiveMenu = () => {
    context?.handleIsActiveMenu();
  };

  const context = useContext(ThemeContext);

  return (
    <>
      <div className={cx("container")}>
        <div className={cx("logo")}></div>
        <div className={cx("logout")}>
          <ul className={cx("list-menu")}>
            <li className={cx("adminActive")}>
              Welcome Admin
              <MdOutlineKeyboardArrowDown />
              <div className={cx("active-logout")} onClick={handleLogout}>
                LogOut
                <BiLogOutCircle />
              </div>
            </li>
            <li className={cx("icon-mobile")} onClick={hanldeActiveMenu}>
              <RiMenu3Fill />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
