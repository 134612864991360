import Config from "../config/config";
import AxiosInstance from "../utils/axios/AxiosInstance";

const createGameVersion = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "createGameVersion", data);
};

const editGameVersion = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "editGameVersion", data);
};

const countGameVersions = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "countGameVersions", data);
};

const listGameVersions = (data: any) => {
  return AxiosInstance.post(Config.apiUrl + "listGameVersions", data);
};


const GameVersionService = {
    createGameVersion,
    editGameVersion,
    countGameVersions,
    listGameVersions
};

export default GameVersionService;
